(function () {
    'use strict';

    angular.module('spidwebApp')
        .controller('AgecController', AgecController);

    AgecController.$inject = ['envService'];

    function AgecController() {
        var vm = this;

        vm.fileTypes = [
            { value: 'BONUS_PENALTIES', label: 'Bonus penalties' },
            { value: 'TRACEABILITY', label: 'Traceability' }
        ];
        vm.labels = {
            title: 'AGEC File Upload',
            fileLabel: 'Pick the AGEC file from your workstation:',
            typeLabel: 'Pick the file type:',
            uploadButton: 'Upload',
            fileError: 'File is required',
            typeError: 'File type is required',
            successMessage: 'AGEC file uploaded successfully',
            errorMessage: 'AGEC file upload failed'
        };
    }
})();
