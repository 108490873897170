(function () {
    'use strict';

    angular.module('spidwebApp')
        .controller('ReferentialController', ReferentialController);

    ReferentialController.$inject = ['envService'];

    function ReferentialController() {
        var viewModel = this;

        viewModel.fileTypes = [
            { value: 'STRUCTURATION', label: 'Structuration' }
        ];
        viewModel.labels = {
            title: 'Referential File Upload',
            fileLabel: 'Select a Referential file:',
            typeLabel: 'Select file category:',
            uploadButton: 'Upload File',
            fileError: 'You must select a file',
            typeError: 'Please choose a file category',
            successMessage: 'Referential file uploaded successfully',
            errorMessage: 'Referential file upload failed'
        };
    }
})();
