(function () {
    'use strict';

    angular.module('spidwebApp').component('fileUpload', {
        bindings: {
            fileTypes: '<',
            labels: '<'
        },
        controller: FileUploadController,
        template: `
            <div id="file-upload">
                <h4 class="st-title-header decathlon-blue page-header-title" translate>{{ $ctrl.labels.title }}</h4>
                <div style="width: 80vw; height: 60vh; margin: auto; display: flex; flex-direction: column;">
                    <form method="post" ng-submit="$ctrl.handleSubmit($event)" enctype="multipart/form-data">
                        <br>
                        <label for="file-input">{{ $ctrl.labels.fileLabel }}</label><span style="color:red;font-weight: bold">*</span>
                        <input type="file" id="file-input" name="file-input" accept=".csv" ng-required="true"/>
                        <br><br>
                        <label for="file-type">{{ $ctrl.labels.typeLabel }}</label><span style="color:red;font-weight: bold">*</span>
                        <select id="file-type" name="file-type" ng-required="true">
                            <option value=""></option>
                            <option ng-repeat="type in $ctrl.fileTypes" value="{{ type.value }}">{{ type.label }}</option>
                        </select>
                        <div style="display: flex; justify-content: flex-end; align-items: center;">
                            <button id="upload-file" class="btn btn-primary" type="submit">{{ $ctrl.labels.uploadButton }}</button>
                        </div>
                    </form>
                </div>
            </div>
        `
    });

    FileUploadController.$inject = ['toastService', 'axiosService', 'envService'];

    function FileUploadController(toastService, axiosService, envService) {
        let vm = this;

        vm.handleSubmit = function ($event) {
            const fileType = $event.target['file-type'].value;
            const file = $event.target['file-input'].files[0];

            if (!fileType) {
                toastService.displayToast(vm.labels.typeError, 'error');
                return;
            }
            if (!file) {
                toastService.displayToast(vm.labels.fileError, 'error');
                return;
            }

            const url = `${envService.getUploadUrl()}?fileType=${fileType}`;
            const formData = new FormData();
            formData.append('file', file);

            axiosService.post(url, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                maxBodyLength: 5242880, // 5 MB
                maxContentLength: 5242880, // 5 MB
            }).then(() => {
                toastService.displayToast(vm.labels.successMessage, 'success');
            }).catch(error => {
                console.error('File upload failed', error);
                toastService.displayToast(vm.labels.errorMessage, 'error');
            });
        };
    }
})();
