(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('MainController', MainController);

    MainController.$inject = ['$scope', '$sce', '$rootScope', 'userSettings', 'mainService', 'parameterService'];

    function MainController ($scope, $sce, $rootScope, userSettings, mainService, parameterService) {
        $scope.initHomePage = initHomePage;

        var deregTranslateChangeStart = $rootScope.$on('$translateChangeStart', function () {
            initHomePage();
        });


        $scope.$on('$destroy', function() {
            deregTranslateChangeStart();
        });

        parameterService.getParameter('feature', 'dashboard.completion.enabled').then(function (resp) {
            $scope.isDashboardCompletionEnabled = (resp.trim().toLowerCase() === 'true');
        });

        function initHomePage() {
            initMessages();
            initPerimeterSpid();
        }

        function initMessages() {
            mainService.getItMessage().then(function (welcomeMessMoe) {
                $scope.welcomeMessMoe = $sce.trustAsHtml(welcomeMessMoe);
            });
            mainService.getNewsMessage().then(function (welcomeMessMoa) {
                $scope.welcomeMessMoa = $sce.trustAsHtml(welcomeMessMoa);
            });
        }

        function initPerimeterSpid() {
            $scope.perimeterImgLink = $scope.contentsURL + '/resources/perimeter/' + userSettings.getSelectedIhmLanguage() + '.JPG';
        }

        $scope.user = userSettings.getUser();
        $scope.weekNumber = moment().format('WW');
        $scope.dateToday = new Date();
        $scope.isAdminOrCUDInt = userSettings.hasRole('ROLE_ADMIN') || userSettings.hasRole('ROLE_CUD_INT');
    }

})();
