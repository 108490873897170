(function() {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ManualCreationController', ManualCreationController);

    ManualCreationController.$inject = ['$scope', '$timeout', '$state', '$uibModalInstance', '$filter', '$rootScope', 'brands', 'productNatures', 'modelStartDate', 'family', 'universeId', 'productService', 'superModelService', 'userSettings', 'canEditSeason', 'localeMappings'];

    function ManualCreationController ($scope, $timeout, $state, $uibModalInstance, $filter, $rootScope, brands, productNatures, modelStartDate, family, universeId, productService, superModelService, userSettings, canEditSeason, localeMappings) {

        $scope.localeMappings = localeMappings;
        $scope.modelDefaultLocale = userSettings.getSelectedWorkingLanguage() + '_' + userSettings.getSelectedWorkingCountry();
        $scope.messageErrorServer = undefined;

        this.checkProductSellingDateRange = function () {
            $scope.startWeekOptions = {
                startingDay: 1,
                showWeeks: true,
                minDate: moment().toDate(),
                maxDate: $scope.datePickerEndDate ? moment($scope.datePickerEndDate).add(-1, 'd').toDate() : null
            };
            $scope.endWeekOptions = {
                startingDay: 1,
                showWeeks: true,
                minDate: $scope.datePickerStartDate ? moment($scope.datePickerStartDate).add(1, 'd').toDate() : moment().add(1, 'd').toDate()
            };
        };

        $scope.canEditSeason = canEditSeason;
        $scope.productNatures = [];
        $scope.refreshProductNature = function () {
            $scope.productNatures = _(productNatures)
                .chain()
                .map(fatherProductNature => getChildren(fatherProductNature))
                .flatten()
                .value();
        };
        $scope.brands = [];
        $scope.refreshBrands = function () {
            $scope.brands = brands;
        };

        function getChildren(productNature) {
            if (productNature.children && productNature.children.length > 0) {
                return _(productNature.children)
                    .chain()
                    .map(child => getChildren(child))
                    .flatten()
                    .value();
            }
            return productNature;
        }

        $scope.family = family[0];

        $scope.model = {
            familyCode: $scope.family.nodeID,
            worldStartDate: null,
            worldEndDate: null,
            locale: null,
            brandId: null,
            productName: null,
            productNatureId: null,
            modelCode: null,
            user: null,
        };

        this.checkProductSellingDateRange();

        $scope.$watch('datePickerStartDate', value => {
            $timeout(() => {
                $scope.wrongDate = value > $scope.datePickerEndDate;
                this.checkProductSellingDateRange();
            });
        }, false);

        $scope.$watch('datePickerEndDate', value => {
            $timeout(() => {
                $scope.wrongDate = $scope.datePickerStartDate > value;
                this.checkProductSellingDateRange();
            });
        }, false);

        $scope.closeAlert = function () {
            $scope.messageErrorServer = undefined;
        };

        $scope.submit = function () {
            $scope.messageErrorServer = undefined;
            $scope.submitDisable = true;
            $scope.model.user = userSettings.getUser().uid;
            $scope.model.worldStartDate = moment($scope.datePickerStartDate).format('YYYY-MM-DDT00:00:00');
            $scope.model.worldEndDate = moment($scope.datePickerEndDate).format('YYYY-MM-DDT23:59:59'); // +23:59:59
            productService
                .createModel($scope.model, true)
                .then(
                    createdModel => { // On createModel success
                        superModelService
                            .saveSuperModelSeasonEvent(createdModel.smSeasonId, createdModel.locale, 'CREA_MAN', createdModel.code)
                            .then(
                                () => $uibModalInstance.close(createdModel))  // On success
                            .catch(
                                () => $uibModalInstance.close(createdModel)   // On error
                            );
                    }).catch(messageError => { // On createModel error
                        $scope.submitDisable = false;
                        $scope.messageErrorServer = messageError.response.data.message;
                        $scope.$apply();
                    });
        };
    }

})();
